<template>
  <index-certifications />
</template>

<script>
import IndexCertifications from '@/components/Certifications/Index';

export default {
  name: 'Certifications',
  components: {
    IndexCertifications
  }
}
</script>

<style scoped>
</style>